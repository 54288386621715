<template>
  <div>
    <v-sheet class="pa-8">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <p
            v-if="stockCountData"
            class="text-h5">
            แก้ไขใบสั่งนับ เลขที่ {{ stockCountData.code }}
          </p>
          <FormInput
            v-if="stockCountData"
            method="edit"
            :loading="loading"
            :value="stockCountData"
            :warehouse="warehouse"
            @onSubmit="onSubmit"
            @onCancel="$router.go(-1)"></FormInput>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import StockCountProvider from '@/resources/StockCountProvider'
import { mapGetters } from 'vuex'
import FormInput from '../components/FormInput.vue'

export default {
  components: {
    FormInput
  },
  data () {
    return {
      stockCountProvider: new StockCountProvider(),
      stockCountData: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouse () {
      return this.mapWarehouse.filter((wh) => wh.code !== 'main')
    }
  },
  mounted () {
    this.fetchById(this.$route.params.id)
  },
  methods: {
    async fetchById (id) {
      try {
        const { data } = await this.stockCountProvider.getById(id)
        if (data) {
          this.stockCountData = data
        }
      } catch (error) {
        console.error('errorFetchStockCountById', error)
      }
    },
    async onSubmit (val) {
      this.loading = true
      const stockCountData = { ...val }
      if (stockCountData.note !== undefined) {
        delete stockCountData.note
      }
      try {
        await this.stockCountProvider.updateById(this.stockCountData.id, val)
        this.$router.push({ name: 'StockCountPreview', params: { id: this.stockCountData.id } })
      } catch (error) {
        console.error('errorUpdateStockCountById', error)
      }
      this.loading = false
    }
  }
}
</script>
