<template>
  <div>
    <validation-observer
      ref="stockCountInputForm"
      tag="form"
      @submit.prevent="submitForm()">
      <v-row
        justify="center">
        <v-col
          align-self="center"
          cols="12">
          <autocomplete-form
            v-model="formData.warehouse"
            :disabled="method !== 'create'"
            :items="warehouse"
            item-text="name"
            out-field-label="Warehouse/Location"
            placeholder="Select"
            name="Warehouse"
            return-object
            outlined
            :rules="formRules.warehouse" />
        </v-col>
        <v-col
          cols="12">
          <date-picker-form
            v-model="formData.countDate"
            out-field-label="วันที่นับ"
            placeholder="วันที่นับ"
            name="Count Date"
            outlined
            :rules="formRules.countDate" />
        </v-col>
        <v-col
          v-if="method === 'create'"
          cols="12">
          <text-area-form
            v-model="formData.note"
            outlined
            :rules="formRules.note"
            out-field-label="Note"
            placeholder="Note"
            name="Note" />
        </v-col>
        <v-col
          class="text-center"
          cols="12">
          <v-btn
            class="mr-4"
            :disabled="loading"
            color="secondary"
            outlined
            @click="onCancel">
            CANCEL
          </v-btn>
          <v-btn
            :disabled="loading"
            color="secondary"
            type="submit">
            SUBMIT
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
export default {
    props: {
        value: {
          type: Object,
          default: null
        },
        method: {
            type: String,
            default: 'create'
        },
        warehouse: {
          type: Array,
          default: () => []
        },
        loading: {
          type: Boolean,
          default: false
        }
    },
    data () {
        return {
            formData: {
                countDate: null,
                warehouse: null,
                note: ''
            },
            formRules: {
                warehouse: { required: true },
                note: { required: false },
                countDate: { required: false }
            }
        }
    },
    mounted () {
      if (this.method === 'edit' && this.value) {
        this.formData = {
          countDate: this.value.countDate || null,
          warehouse: this.value.warehouse || null,
          note: this.value.note || ''
        }
      }
    },
    methods: {
        async submitForm () {
            const valid = await this.$refs.stockCountInputForm.validate()
            if (valid) {
                this.$emit('onSubmit', this.formData)
            }
        },
        async onCancel () {
          this.$emit('onCancel')
        }
    }
}
</script>

<style scoped>
</style>
