import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockCountProvider extends HttpRequest {
  createStockCount (payload) {
    this.setHeader(getAuthToken())
    return this.post('/stock-count', payload)
  }

  paginate (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-count', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/stock-count/${id}`)
  }

  updateById (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-count/${id}`, payload)
  }

  updateStatusById (id, status) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-count/${id}/${status}/status`)
  }

  addNote (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/stock-count/${id}/add-note`, payload)
  }

  deleteById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/stock-count/${id}`)
  }

  getStockCountByWarehouseId (payload) {
    this.setHeader(getAuthToken())
    return this.get('/stock-count/warehouse', payload)
  }
}

export default StockCountProvider
